import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb846221"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layout-main-section row"
}
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "print-format-container col-9" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.shouldRender)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["PrintFormatControls"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            ($setup.show_preview)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.Preview), { key: 0 }))
              : (_openBlock(), _createBlock(_resolveDynamicComponent($setup.PrintFormat), { key: 1 }))
          ], 1024 /* DYNAMIC_SLOTS */))
        ])
      ]))
    : _createCommentVNode("v-if", true)
}