import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82ec7c8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "html-editor" }
const _hoisted_2 = { class: "d-flex justify-content-end" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { ref: "editor" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-default btn-xs btn-edit",
        onClick: $setup.toggle_edit
      }, _toDisplayString(!$setup.editing ? _ctx.buttonLabel : _ctx.__("Done")), 1 /* TEXT */)
    ]),
    (!$setup.editing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: $props.value
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */), [
      [_vShow, $setup.editing]
    ])
  ]))
}